import React, { useState } from 'react'
import { ListItem, UnorderedList } from '@chakra-ui/react'
import { Link } from 'gatsby'

const Nav = props => {
  const [toggle, setToggle] = useState(false)
  const handleToggle = () => setToggle(!toggle)

  return (
    <UnorderedList
      flexWrap="no-wrap"
      styleType="none"
      m={0}
      p={0}
      sx={{
        li: {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          mb: 0,
          textAlign: 'center',
        },
        'li a.active, li a:hover': {
          borderBottomColor: 'primary',
          borderBottomStyle: 'solid',
          borderBottomWidth: 2,
        },
        a: {
          color: 'black',
          textDecoration: 'none',
          fontSize: 'sm',
          fontWeight: '600',
          verticalAlign: 'middle',
          alignSelf: 'center',
          minWidth: [20],
          py: [2],
          borderBottomColor: 'transparent',
          borderBottomWidth: 2,
        },
      }}
      {...props}
    >
      <ListItem>
        <Link to="/" activeClassName="active">
          Home
        </Link>
      </ListItem>
      <ListItem>
        <Link to="/airport" activeClassName="active">
          Airport
        </Link>
      </ListItem>
      <ListItem>
        <Link to="/flights" activeClassName="active">
          Flights
        </Link>
      </ListItem>
      <ListItem>
        <Link to="/resources" activeClassName="active">
          Resources
        </Link>
      </ListItem>
      <ListItem>
        <Link to="/events" activeClassName="active">
          Events
        </Link>
      </ListItem>
      <ListItem>
        <Link to="/contact" activeClassName="active">
          Contact
        </Link>
      </ListItem>
    </UnorderedList>
  )
}

export default Nav
